import styled from '@emotion/styled'
import tw from '@tailwindcssinjs/macro'
import React from 'react'

export default function Custom404() {
  return (
    <Container>
      <ErrorWrapper>
        <h1>404</h1>
        <h2>Welp... the page you are looking for is not here.</h2>
      </ErrorWrapper>
    </Container>
  )
}

const Container = styled.div(tw`
  h-screen
  w-full
  flex
  justify-center
  items-center
`)

const ErrorWrapper = styled.div(tw`
flex
flex-col
items-center
`)
